<template>
  <div class="daily-question-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <h4>{{title}}</h4>
        <div
          class="option"
          :class="optionClass(options[index])"
          v-for="(item, index) in answerNum"
          :key="item"
          @click="handleSelectOption(options[index])">
          <span>{{options[index]}}.</span>{{answers[index]}}
        </div>
        <div class="btn" v-if="!isAnswer" @click="answerQuestion">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
import { saveLoading } from '@/utils/utils'
const { getDailySubject, saveDailySubjectResult } = API
export default {
  name: 'dailyQuestion',
  data () {
    return {
      title: '',
      answerNum: 0, // 选项总数
      answer: '', // 正确答案
      selectOption: '', // 用户选择的
      isAnswer: false, // 是否回答完成
      options: ['A', 'B', 'C', 'D'],
      answers: [] // 选项答案
    }
  },
  created () {
    this.getDailySubject()
  },
  methods: {
    // 获取今日题目
    async getDailySubject () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })

      let res = await getDailySubject()

      loading.clear()

      if (res.success) {
        let data = res.data
        if (data) {
          this.title = data.topic
          this.subjectId = data.idDailySubject
          this.answerNum = data.answerNum
          this.answers = [data.answerA, data.answerB, data.answerC, data.answerD]

          this.isAnswer = data.answerStat
          this.selectOption = data.optionChosen ? data.optionChosen.toUpperCase() : ''
          this.answer = data.rightAnswer.toUpperCase() // 正确答案
          if (this.isAnswer) {
            this.$toast('今天考试已结束，请明天再来吧！')
          }
        } else {
          this.$toast('今天考试已结束，请明天再来吧！')
        }
      } else {
        this.$toast(res.message)
      }
    },
    optionClass (option) {
      if (this.isAnswer) {
        if (option === this.answer) {
          return 'success'
        } else if (option === this.selectOption) {
          return 'error'
        }
      } else {
        if (option === this.selectOption) {
          return 'active'
        } else {
          return ''
        }
      }
    },
    // 选择选项
    handleSelectOption (option) {
      this.selectOption = option
    },
    // 回答问题
    answerQuestion () {
      if (this.selectOption) {
        saveLoading({
          data: {
            subjectId: this.subjectId,
            resultStat: this.answer === this.selectOption,
            option: this.selectOption
          },
          api: saveDailySubjectResult,
          success: () => {
            this.isAnswer = true
          }
        })
      } else {
        this.$toast('请先回答问题')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .daily-question-wrapper{
    .card-box{
      height: 100%;
      padding: 54px;
      overflow: auto;
      h4{
        font-size: 34px;
        font-weight: 600;
        color: #333;
        text-indent: 68px;
        line-height: 1.5;
        margin: 18px 0 80px;
      }
      .option{
        width: 594px;
        min-height: 95px;
        font-size: 28px;
        background: #F7FAFB;
        border-radius: 48px;
        padding: 26px 60px;
        line-height: 1.5;
        box-sizing: border-box;
        margin-bottom: 32px;
        color: #666;

        span{
          color: #333;
          font-size: 30px;
          font-weight: 600;
          margin-right: 12px;
        }
        &.active{
          background: #DAEAFF;
        }
        &.success, &.error{
          color: #fff;
          position: relative;
          span{
            color: #fff;
          }
          &::after{
            font-family: "iconfont" !important;
            font-size: 26px;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            right: 46px;
            top: 50%;
            transform: translate(50%, -50%);
          }
        }
        &.success{
          background: #01C1A3;
          &::after{
            content: "\e66c";
          }
        }
        &.error{
          background: #FF5757;
          &::after{
            content: "\e67e";
          }
        }
      }

      .btn{
        width: 596px;
        height: 95px;
        background: linear-gradient(to left, #0dafff, #064dff);
        box-shadow: 0px 8px 13px 0px rgba(5,102,206,0.43);
        border-radius: 48px;
        line-height: 95px;
        text-align: center;
        font-size: 32px;
        color: #fff;
        font-weight: 500;
        margin-top: 156px;
      }
    }
  }
</style>
